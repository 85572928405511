<template>
  <div class="ml-5">
    <v-row class="">
      <v-col>
        <h1 class="float-left">My Orders</h1>
      </v-col>

      <v-col>
        <router-link to="/order/draft">
          <v-btn class="primary float-right">
            <v-icon>mdi-plus-circle </v-icon> New Order
          </v-btn>
        </router-link>
      </v-col>
    </v-row>

    <v-card flat class="mt-5" color="transparent">
      <v-tabs background-color="transparent" class="mt-3">
        <v-tab key="tab1" to="/dashboard/orders/open">Opened</v-tab>
        <v-tab key="tab2" to="/dashboard/orders/draft">Drafts</v-tab>
        <v-tab key="tab3" to="/dashboard/orders/closed">Closed</v-tab>
      </v-tabs>
      <v-divider class="mt-0" color="grey"></v-divider>
    </v-card>
     <router-view class="mt-5"></router-view>
  </div>
</template>

<script>
export default {
  name: "Myorders",
  data() {
    return {
      msg: ""
    };
  }
};
</script>

<style scoped>
.icon {
  width: 70px;
  text-align: left;
}
.Field {
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-weight: 500;
  border: 2px solid #a2a3a5;
  border-radius: 4px;
}

.inputContainer i {
  position: absolute;
}
</style>
